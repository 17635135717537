import React from "react";
import PriceCard from "../card/PriceCard";
import { plansData } from "../../data/plansData";
import './Plan.css'
const Plan = () => {
    return (
        <div className="plans" id="priceplan">
            <div className="plan-header">
                <span className="stroke-text">ready to start</span>
                <span>Your journey</span>
                <span className="stroke-text">now with us</span>
            </div>
            <div className="plan-price">
                {plansData.map((plan, i) =>
                (
                    <PriceCard icon={plan.icon} name={plan.name} price={plan.price} features={plan.features} id={i} />
                ))
                }
            </div>

        </div>
    )
}
export default Plan;