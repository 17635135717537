import React from "react";
import './ReasonDesc.css'
import tick from "../../assets/tick.png"
const ReasonDesc = (props) => {
    return (
        <div className="reason-desc">
            <img src={tick} alt="" />
            <span>{props.descVal}</span>
        </div>
    )
}
export default ReasonDesc;