import React from "react";
import "./Footer.css"
import logo from "../../assets/logo.png"
import insta from "../../assets/instagram.png"
import linkedin from "../../assets/linkedin.png"
import github from "../../assets/github.png"


const Footer = () => {
    return (
        <div className="footer-container" id="contact">
            <hr />
            <div className="footer">
                <div className="social-links">
                    <img src={github} alt="" />
                    <img src={insta} alt="" />
                    <img src={linkedin} alt="" />
                </div>
                <img src={logo} alt="" />
            </div>
        </div>
    )
}
export default Footer;