import React, { useState } from "react";
import "./Header.css";
import Logo from "../../assets/logo.png";
import Hamburger from 'hamburger-react'
import { Link } from "react-scroll";
const Header = () => {
    const mobile = window.innerWidth <= 768 ? true : false;
    const [menuOpened, setMenuOpened] = useState(false);
    // const [isOpen, setOpen] = useState(false)
    return (
        <div className="header">
            <img src={Logo} alt="" className="logo" />
            {menuOpened === false && mobile === true ? (
                <Hamburger toggled={menuOpened} toggle={setMenuOpened} size={25} color="white" duration={0.8} />
            ) : (
                <ul className="header-menu">
                    {/* <li >Home</li> */}
                    <Link
                        to="home"
                        smooth={true}
                        spy={true}
                        activeClass="active"
                        onClick={() => setMenuOpened(false)}
                    >
                        Home
                    </Link>
                    <Link
                        to="about"
                        smooth={true}
                        spy={true}
                        onClick={() => setMenuOpened(false)}
                    >
                        Programs
                    </Link>
                    <Link
                        to="whyus"
                        smooth={true}
                        spy={true}
                        onClick={() => setMenuOpened(false)}
                    >
                        Why Us
                    </Link>
                    <Link
                        to="priceplan"
                        smooth={true}
                        spy={true}
                        onClick={() => setMenuOpened(false)}
                    >
                        Plan
                    </Link>
                    <Link
                        to="testimonials"
                        smooth={true}
                        spy={true}
                        onClick={() => setMenuOpened(false)}
                    >
                        Testimonials
                    </Link>
                    {/* <li>Why us</li>
<li>Contact Us</li>
<li>Testimonials</li> */}
                </ul>
            )}
        </div>
    );
};
export default Header;
