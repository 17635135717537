import React from "react";
import './PriceCard.css'
import tick from "../../assets/whiteTick.png"
import arrow from "../../assets/rightArrow.png"
const PriceCard = (props) => {
    return (
        <div className="price-card">
            {props.icon}
            <span>{props.name}</span>
            <span>$ {props.price}</span>
            <ul>
                <div>
                    <img src={tick} alt="" />
                    <span>{props.features[0]}</span>
                </div>
                <div>
                    <img src={tick} alt="" />
                    <span>{props.features[1]}</span>
                </div>
                <div>
                    <img src={tick} alt="" />
                    <span>{props.features[2]}</span>
                </div>
            </ul>
            <div>
                <span>See more benefits</span>
                <img src={arrow} alt="" />
            </div>
            <button className="global-button">Join Now</button>
        </div>
    )
}
export default PriceCard