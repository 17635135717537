import React from "react";
import "./JoinUs.css"
// import emailjs from '@email.js/browser'
const JoinUs = () => {
    return (
        <div className="join" id="joinus">
            <div className="join-left">
                <hr />
                <div>

                    <span className="stroke-text">Ready to</span>
                    <span>level up</span>
                </div>
                <div>
                    <span>your body</span>
                    <span className="stroke-text">with us?</span>
                </div>
            </div>
            <div className="join-right">
                <form
                // ref={form} onSubmit={sendEmail}
                >
                    <input type="email" id="email" placeholder="Enter your Email Address here" />
                    {/* <button className="global-button">Join Now</button> */}
                    <button className="global-button">Join Now</button>
                </form>
            </div>
        </div>
    )
}
export default JoinUs;