import React, { useState } from "react";
import './Testimonial.css'
import { testimonialsData } from "../../data/testimonialsData";
import rightarrow from "../../assets/rightArrow.png"
import leftarrow from "../../assets/leftArrow.png"

const Testimonial = () => {
    const [testdata, settestdata] = useState(0);
    const tlength = testimonialsData.length;

    const changeright = () => {
        let temp = testdata;
        if ((temp + 1) >= tlength) {
            temp = -1;
        }
        return (
            settestdata(temp + 1)
        )
    }
    const changeleft = () => {
        let temp = testdata;
        if ((temp) <= 0) {
            temp = tlength;
        }
        return (
            settestdata(temp - 1)
        )
    }
    return (
        <div className="testimonial" id="testimonials">
            <div className="testimonial-left">
                <span>Testimonials</span>
                <span className="stroke-text">What they</span>
                <span>say about us</span>
                <span>{testimonialsData[testdata].review}</span>
                <span>
                    <span style={{ color: 'var(--orange)' }}>{testimonialsData[testdata].name} </span> <span style={{ color: 'white', font_weight: 'bold' }}>- {testimonialsData[testdata].status}</span>
                </span>

            </div>
            <div className="testimonial-right">
                <div></div>
                <div></div>
                <img src={testimonialsData[testdata].image} alt="" />
                <div>
                    <img src={leftarrow} onClick={changeleft} alt="" />
                    <img src={rightarrow} onClick={changeright} alt="" />
                </div>
            </div>

        </div>
    )

}
export default Testimonial;

