import React from "react";
import './Reason.css'
import image1 from "../../assets/image1.png"
import image3 from "../../assets/image3.png"
import image4 from "../../assets/image4.png"
import nblogo from "../../assets/nb.png"
import adidaslogo from "../../assets/adidas.png"
import nikelogo from "../../assets/nike.png"
import ReasonDesc from "./ReasonDesc";
const Reason = () => {
    const descArr = ["OVER 140+ EXPERT COACHS", "TRAIN SMARTER AND FASTER THAN BEFORE", "1 FREE PROGRAM FOR NEW MEMBER", "RELIABLE PARTNERS"]
    return (
        <div className="reasons">
            <div className="reasons-left">
                <img src={image1} alt="" />
                <div>

                    <img src={image3} alt="" />
                    <img src={image4} alt="" />
                </div>
            </div>
            <div className="reasons-right" id='whyus'>
                <span>Some Reasons</span>
                <div>
                    <span className="stroke-text">Why </span>
                    <span>Choose us?</span>
                </div>
                <ul>
                    <ReasonDesc descVal={descArr[0]} />
                    <ReasonDesc descVal={descArr[1]} />
                    <ReasonDesc descVal={descArr[2]} />
                    <ReasonDesc descVal={descArr[3]} />

                </ul>

                <span>Our Partners</span>
                <div>
                    <img src={nblogo} alt="" />
                    <img src={adidaslogo} alt="" />
                    <img src={nikelogo} alt="" />
                </div>
            </div>

        </div>
    )
}
export default Reason;