import "./App.css";
import Hero from "./components/hero/Hero";
import Program from "./components/programs/Program";
import Reason from "./components/reasons/Reason";
import Plan from "./components/plan/Plan";
import Testimonial from "./components/testimonial/Testimonial";
import JoinUs from "./components/joinus/JoinUs";
import Footer from "./components/footer/Footer";
// import { Routes, Route } from "react-router-dom";
function App() {
  return (
    <div className="App">
      <Hero />
      <Program />
      <Reason />
      <Plan />
      <Testimonial />
      <JoinUs />
      <Footer />
    </div>
  );
}

export default App;
