import React from "react";
import { Link } from "react-scroll";
import Header from "../header/Header";
import './Hero.css'
import hero_image_back from "../../assets/hero_image_back.png"
import Heart from "../../assets/heart.png"
import Calories from "../../assets/calories.png"
import NumberCounter from 'number-counter'
const Hero = () => {
    return (
        <div className="hero" id="home">
            <div className="left-h">
                <Header />
                <div className="the-best-ad">
                    <div></div>
                    <span>Best Fitness Club in the town</span>
                </div>
                {/* Hero Heading */}
                <div className="hero-text">

                    <div>
                        <span className="stroke-text">Shape </span>
                        <span>Your</span>
                    </div>
                    <div>
                        <span>Ideal Body
                        </span>
                    </div>
                    <div>
                        <span>
                            In here we help you to shape and build your ideal body and live up your life to fullest
                        </span>
                    </div>
                </div>
                {/* figures */}
                <div className="figures">
                    <div>
                        <NumberCounter end={140} start={100} delay='4' preFix="+" />
                        {/* <span>+ 140</span> */}
                        <span>Expert Coaches</span>
                    </div>
                    <div>
                        <NumberCounter end={978} start={800} delay='4' preFix='+' />
                        {/* <span>+ 978</span> */}
                        <span>Members Joined</span>
                    </div>
                    <div>
                        {/* <span>+ 50</span> */}
                        <NumberCounter end={50} start={0} delay='2' preFix='+' />
                        <span>Fitness programs</span>
                    </div>
                </div>
                {/* Hero buttons */}
                <div className="hero-button">
                    <button className="global-button">Get Started</button>
                    <button className="global-button">Learn More</button>
                </div>
            </div>
            <div className="right-h">
                <Link to='joinus' smooth={true} spy={true} className="global-button">
                    Join Now
                </Link>
                {/* <button className="global-button">
                    Join Now
                </button> */}

                <div className="heart-rate">
                    <img src={Heart} alt="" />
                    <span>Heart Rate</span>
                    <span>116 bpm</span>
                </div>
                {/* hero images */}
                {/* <img src={hero_image} alt="" className="hero-image" /> */}
                <img src={hero_image_back} alt="" className="hero-image-back" />
                <div className="calories">

                    <img src={Calories} alt="" />

                    <div>
                        <span>Calories Burned</span>
                        <span>220 kcal</span>

                    </div>
                </div>
            </div>
        </div>
    )
}
export default Hero;